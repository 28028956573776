import React, { useEffect, useState, useRef } from "react";
import { HcpPage, SEO } from "components";
import { unitedStates } from "libs/util.helper";
import XForm from "components/shared/x-form";
import moment from "moment";
import axios from "axios";
import { loadReCaptcha, ReCaptcha } from "react-recaptcha-v3";
import { whichSite } from "../../codes/libs/util.helper";

//import "@ilama007/x-form/dist/blueprint-light.css";
import "./aveed-finder-signup.scss";

const DFA_API_HCP_SIGNUP_URL = process.env.DFA_API_HCP_SIGNUP_URL;
const DFA_API_EMAIL_SERVICE_URL = process.env.DFA_API_EMAIL_SERVICE_URL;

const RECAPTCHA_SITE_KEY =
  whichSite() === "integration"
    ? "6LegD2EbAAAAAJH-93XiE33VnNQ1LdDEl4thTK0M"
    : "6LegD2EbAAAAAJH-93XiE33VnNQ1LdDEl4thTK0M"; // Will have to change it to endo's recaptcha key for production once it is provided

const pageDescription =
  "Sign up to be listed on the AVEED® (testosterone undecanoate) finder.";

const mySchema = [
  {
    name: "first_name",
    label: "First Name",
    labelInfo: "*",
    type: "text",
    placeHolder: "",
    maxLength: 50,
    minLength: 1,
  },
  {
    name: "middle_name",
    label: "Middle Name",
    labelInfo: "",
    type: "text",
    placeHolder: "",
    maxLength: 50,
    minLength: 1,
  },
  {
    name: "last_name",
    label: "Last Name",
    labelInfo: "*",
    type: "text",
    placeHolder: "",
    maxLength: 50,
    minLength: 1,
  },
  {
    name: "email",
    label: "Email Address",
    labelInfo: "*",
    type: "email",
    placeHolder: "",
    helperText: "Your email address will not be published on AVEEDfinder.",
  },
  {
    name: "address1",
    label: "Address 1",
    labelInfo: "*",
    type: "text",
    placeHolder: "",
    maxLength: 100,
    minLength: 1,
  },
  {
    name: "address2",
    label: "Address 2",
    labelInfo: "",
    type: "text",
    placeHolder: "",
    maxLength: 100,
    minLength: 1,
  },
  {
    name: "city",
    label: "City",
    labelInfo: "*",
    type: "text",
    placeHolder: "",
    maxLength: 50,
    minLength: 1,
  },
  {
    name: "us_states",
    label: "State",
    labelInfo: "*",
    type: "selectlist",
    options: unitedStates,
  },
  {
    name: "zip",
    label: "ZIP Code",
    labelInfo: "*",
    type: "text",
    placeHolder: "",
    maxLength: 20,
    minLength: 1,
  },
  {
    name: "practice_name",
    label: "Practice Name",
    labelInfo: "*",
    type: "text",
    placeHolder: "",
    maxLength: 200,
    minLength: 5,
  },
  {
    name: "practice_phone",
    label: "Practice Phone",
    labelInfo: "*",
    type: "text",
    placeHolder: "",
    maxLength: 50,
    minLength: 5,
  },
  {
    name: "practice_website",
    label: "Practice Website",
    labelInfo: "",
    type: "text",
    placeHolder: "",
    maxLength: 50,
    minLength: 5,
  },
  {
    name: "npi_number",
    label: "NPI Number",
    labelInfo: "*",
    type: "text",
    placeHolder: "",
    maxLength: 100,
    minLength: 5,
  },
  {
    name: "dea_number",
    label: "DEA Registration Number",
    labelInfo: "*",
    type: "text",
    placeHolder: "",
    maxLength: 100,
    minLength: 5,
  },

  {
    name: "agreement_checkbox",
    label: "Please check to indicate your agreement:",
    labelInfo: "",
    type: "checkboxgroup",
    options: [
      {
        name: "receive_info",
        label:
          "Yes, I would like to receive information in the future about AVEED<sup>&reg;</sup> and related product information.",
      },
      {
        name: "add_to_aveedfinder",
        label:
          "Yes, I would like my information to be included in AVEEDfinder on AVEEDfinder.com to connect patients searching for physicians who prescribe AVEED<sup>&reg;</sup>.",
      },
    ],
  },
];

const myValidation = (values) => {
  let errors = {};
  if (!values.first_name) {
    errors.first_name = "▲ First Name is required.";
  }
  if (!values.last_name) {
    errors.last_name = "▲ Last Name is required.";
  }
  if (!values.email) {
    errors.email = "▲ Email Address is required.";
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = "▲ Email Address is invalid.";
  }
  if (!values.address1) {
    errors.address1 = "▲ Address 1 is required.";
  }
  if (!values.city) {
    errors.city = "▲ City is required.";
  }
  if (!values.us_states) {
    errors.us_states = "▲ State is required.";
  }
  if (!values.zip) {
    errors.zip = "▲ ZIP Code is required.";
  }
  if (!values.practice_name) {
    errors.practice_name = "▲ Practice Name is required.";
  }
  if (!values.practice_phone) {
    errors.practice_phone = "▲ Practice Phone is required.";
  }

  if (!values.dea_number) {
    errors.dea_number = "▲ DEA Registration Number is required.";
  }
  if (!values.add_to_aveedfinder && !values.receive_info) {
    errors.agreement_checkbox =
      "▲ You must select at least one from the above options.";
  }
  if (!values.npi_number) {
    errors.npi_number = "▲ NPI Number is required.";
  }

  return errors;
};

const myInitialValues = {
  first_name: "",
  middle_name: "",
  last_name: "",
  email: "",
  address1: "",
  address2: "",
  city: "",
  us_states: "",
  zip: "",
  practice_name: "",
  practice_phone: "",
  practice_website: "",
  npi_number: "",
  dea_number: "",
  receive_info: false,
  add_to_aveedfinder: false,
};
const AveedSignupPage = () => {
  const recaptcha = useRef(null);
  const [showForm, setShowForm] = useState(true);
  const [checkingReCaptchaForSubmit, setCheckingReCaptchaForSubmit] = useState(
    false
  );
  const [dataFromForm, setDataFromForm] = useState(null);
  const [reCaptchaToken, setReCaptchaToken] = useState("");

  useEffect(() => {
    loadReCaptcha(RECAPTCHA_SITE_KEY, () => {});
  }, []);

  const onFormSucess = (response) => {
    console.log(response);
    setShowForm(false);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const verifyCallback = async (generatedToken) => {
    if (checkingReCaptchaForSubmit && dataFromForm) {
      await setCheckingReCaptchaForSubmit(false);
      setReCaptchaToken(generatedToken);
      await onSuccessHandler(dataFromForm);
    }
  };

  const updateRecaptchaToken = () => {
    recaptcha.current.execute();
  };

  const handleSubmitForCaptcha = async (data) => {
    setDataFromForm(data);
    await setCheckingReCaptchaForSubmit(true);
    updateRecaptchaToken();
  };

  const onSuccessHandler = async (data) => {
    const timeNow = moment().format("MM/DD/YYYY hh:mm:ss");

    const sfmcData = {
      keys: {
        EMAIL: data.email,
        BRND_MDM_ID: "5",
        PAGE_TYPE: "hcp_optin",
        INSERTED_DATE: timeNow,
      },
      values: {
        FRST_NM: data.first_name,
        LAST_NM: data.last_name,
        ClientID: "110005580",
        REG_ID: "",
        BRND_OPTIN: "Y",
        BRND_OPTIN_TXT:
          "Yes, I would like to receive information in the future about AVEED and related product information.",
        BRND_OPTIN_SOURCE: "sfmc_www.aveedusa.com",
        IS_UNBRANDED: "N",
      },
    };
    // const config = {
    //   headers: {
    //     "Content-Type": "application/json",
    //     "Access-Control-Allow-Origin": "*",
    //     "Access-Control-Allow-Methods": "*",
    //   },
    // };

    if (data.receive_info && data.add_to_aveedfinder) {
      // Send data to SFMC and email to HCPLocator@endo.com
      console.log(data);
      axios
        .post(DFA_API_HCP_SIGNUP_URL, JSON.stringify(sfmcData))
        .then((response) => {
          console.log(response.data);
          //Clear state
          axios
            .post(DFA_API_EMAIL_SERVICE_URL, JSON.stringify(data))
            .then((response) => {
              console.log("response from mailer");
              onFormSucess(response);
            })
            .catch((error) => {
              console.log("error", error);
            });
        });
    } else if (data.receive_info) {
      // Send data to SFMC
      console.log(data);
      axios
        .post(DFA_API_HCP_SIGNUP_URL, JSON.stringify(sfmcData))
        .then((response) => {
          onFormSucess(response.data);
        });
    } else if (data.add_to_aveedfinder) {
      // Email data to HCPLocator@endo.com
      console.log(data);
      axios
        .post(DFA_API_EMAIL_SERVICE_URL, JSON.stringify(data))
        .then((response) => {
          onFormSucess(response.data);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };
  return (
    <HcpPage pageClassName="aveed-finder-signup" pageName="aveed-finder-signup">
      <SEO
        pageTitle="AVEED® Finder Sign-up"
        pageDescription={pageDescription}
      />
      <div className="dfa-container gutter-left gutter-right">
        <h1>Connect with Prospective Patients</h1>
        {showForm && (
          <div className="signup-form-container">
            <h2>Sign up to have your information added to AVEEDfinder.</h2>
            <p>All fields marked with * are required.</p>
            <XForm
              name="frm-survey"
              okButtonTitle="Sign Up"
              disableButtons={false}
              showResetButton={false}
              schema={mySchema}
              initialValues={myInitialValues}
              validationHandler={myValidation}
              // successHandler={onSuccessHandler}
              successHandler={handleSubmitForCaptcha}
            />
            <ReCaptcha
              // ref={(ref) => (recaptcha = ref)}
              ref={recaptcha}
              sitekey={RECAPTCHA_SITE_KEY}
              verifyCallback={verifyCallback}
            />
          </div>
        )}

        {!showForm && (
          <div className="signup-form-container">
            <h2>Thank you! Your submission will be processed.</h2>
          </div>
        )}
      </div>
    </HcpPage>
  );
};

export default AveedSignupPage;
